import PropTypes from "prop-types";

function ConsumptionManagementReportIcon({ className = "", strokeColor = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="834"
      height="850"
      viewBox="124 155 160 168"
      fill="none"
      className={className}
    >
      <path
        stroke={strokeColor}
        fill="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.79 277.786v-82.993h61.717v16.446h17.278v66.547H164.79zm70.642-58.185h-17.279v-16.446h-45.009v66.269h62.288v-49.823zm-51.516 35.854c.699 0 1.354.168 1.947.446l9.004-7.154c-.008-.106-.032-.208-.032-.316 0-2.499 2.046-4.526 4.569-4.526 2.524 0 4.57 2.027 4.57 4.526 0 .288-.033.568-.085.841l3.843 3.924a4.549 4.549 0 0 1 1.397-.238c.543 0 1.057.11 1.541.283l8.991-9.107-3.055-3.026c-.966-.957-.282-2.592 1.084-2.592h9.137c.846 0 1.533.68 1.533 1.518v9.05c0 1.353-1.651 2.031-2.617 1.074l-3.229-3.199-9.049 9.166c.138.431.233.882.233 1.359 0 2.5-2.046 4.526-4.569 4.526-2.524 0-4.57-2.026-4.57-4.526 0-.531.109-1.032.279-1.505l-3.428-3.499a4.56 4.56 0 0 1-2.006.478c-.913 0-1.76-.273-2.474-.73l-8.551 6.795c.067.309.107.629.107.958 0 2.5-2.046 4.526-4.57 4.526-2.523 0-4.569-2.026-4.569-4.526 0-2.499 2.046-4.526 4.569-4.526zm39.6-22.749c-2.27 0-4.111-1.791-4.111-4.001 0-2.21 1.841-4.001 4.111-4.001 2.271 0 4.111 1.791 4.111 4.001 0 2.21-1.84 4.001-4.111 4.001zm-12.515 0h-27.103c-2.271 0-4.11-1.792-4.11-4.001 0-2.21 1.839-4.001 4.11-4.001h27.103c2.27 0 4.111 1.791 4.111 4.001 0 2.209-1.841 4.001-4.111 4.001zm-7-15h-20.103c-2.271 0-4.11-1.792-4.11-4.001 0-2.21 1.839-4.001 4.11-4.001h20.103c2.27 0 4.111 1.791 4.111 4.001 0 2.209-1.841 4.001-4.111 4.001z"
      />
    </svg>
  );
}

ConsumptionManagementReportIcon.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
};

ConsumptionManagementReportIcon.defaultProps = {
  className: "",
  strokeColor: "",
};

export default ConsumptionManagementReportIcon;
