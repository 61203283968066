import { useEffect, useState } from "react";

import {
  FormOutlined,
  LineChartOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Select, Row, Col, DatePicker, Divider, Form } from "antd";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import PropTypes from "prop-types";

import useWaterConsuptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import DateConstants from "../../../constants/DateConstants";
import "./Filters.scss";
import FiltersModal from "./FiltersModal";

dayjs.extend(localizedFormat);

const dateFormatsForBucketTypeYear = [
  {
    value: DateConstants.formats.fullYear_monthAbbreviation,
    label: DateConstants.formats.fullYear_monthAbbreviation_label,
  },
  {
    value: DateConstants.formats.fullYear_fullMonthName,
    label: DateConstants.formats.fullYear_fullMonthName_label,
  },
  {
    value: DateConstants.formats.monthAbbreviation_fullYear,
    label: DateConstants.formats.monthAbbreviation_fullYear_label,
  },
  {
    value: DateConstants.formats.fullMonthName_fullYear,
    label: DateConstants.formats.fullMonthName_fullYear_label,
  },
];

const dateFormatsForBucketTypeMonth = [
  {
    value: DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
    label:
      DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth_label,
  },
  {
    value: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth,
    label: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth_label,
  },
];

const dateFormatsForBucketTypeDay = [
  {
    value:
      DateConstants.formats.dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
  {
    value:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
];

function Filters({
  handleOnChangeFilterCallback,
  generateReportForm,
  useBasicFiltering,
  showHeader,
  setShowHeader,
  graphics,
  setGraphics,
}) {
  const [disableFilters, setDisableFilters] = useState(true);
  const [isReportDataModalVisible, setIsReportDataModalVisible] =
    useState(false);
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  // comment below applies to the following three states: selectedPropertyId, selectedFloorId & selectedSystemId
  // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    DateConstants.formats.fullYear_monthAbbreviation,
  );
  const [selectedAggregationLevel, setSelectedAggregationLevel] =
    useState("Device");
  const [selectedBucketType, setSelectedBucketType] = useState("year");
  const [selectedIncludePreviousYear, setSelectedIncludePreviousYear] =
    useState(false);
  const [propertyNameField, setPropertyNameField] = useState("Property");
  const [propertyAddressField, setPropertyAddressField] =
    useState("Property Address");
  const [floorNameField, setFloorNameField] = useState("Floor");
  const [systemNameField, setSystemNameField] = useState("System");
  const [deviceNameField, setDeviceNameField] = useState("Device");
  const [deviceSerialNumberField, setDeviceSerialNumberField] =
    useState("Device S/N");
  const [deviceLocationField, setDeviceLocationField] =
    useState("Device Location");
  const [deviceTypeField, setDeviceTypeField] = useState("Device Type");
  const [datePickerOption, setDatePickerOption] = useState("year");
  const [dateFormatOptions, setDateFormatOptions] = useState(
    dateFormatsForBucketTypeYear,
  );
  const [floorOptions, setFloorOptions] = useState([
    { value: "", label: "All Floors" },
  ]);
  const [systemOptions, setSystemOptions] = useState([
    { value: "", label: "All Systems" },
  ]);
  const propertyOptions = [
    {
      value: "",
      label: useBasicFiltering === false ? "All Properties" : "Select Property",
    },
  ];
  const disabledOpacity = "opacity-50";

  // Hooks
  const { data: propertiesObject } = useWaterConsuptionFilters();
  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Effects
  useEffect(() => {
    if (
      selectedPropertyId !== "" &&
      propertiesObject &&
      propertiesObject.Properties
    ) {
      let property = null;
      const tempFloorOptions = [{ value: "", label: "All Floors" }];
      const tempSystemOptions = [{ value: "", label: "All Systems" }];
      propertiesObject.Properties.forEach((p) => {
        if (p.Id === selectedPropertyId) {
          property = p;
        }
      });

      if (property) {
        setDisableFilters(false);
        property.Floors?.forEach((f) => {
          tempFloorOptions.push({ value: f.Id, label: f.Name });

          if (f) {
            f?.Systems?.forEach((s) =>
              tempSystemOptions.push({ value: s.Id, label: s.Name }),
            );
          } else if (selectedFloorId === "") {
            property?.Floors?.forEach((floor) => {
              floor.Systems.map((fs) =>
                tempSystemOptions.push({ value: fs.Id, label: fs.Name }),
              );
            });
          }
        });
      } else {
        setDisableFilters(true);
      }

      setFloorOptions(tempFloorOptions);
      setSystemOptions(tempSystemOptions);
    } else {
      setDisableFilters(true);
    }
  }, [selectedPropertyId, selectedFloorId, propertiesObject]);

  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
    );
  });

  // Effects
  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
      selectedDate,
      selectedDateFormat,
      selectedAggregationLevel,
      selectedBucketType,
      selectedIncludePreviousYear,
      propertyNameField,
      propertyAddressField,
      floorNameField,
      systemNameField,
      deviceNameField,
      deviceSerialNumberField,
      deviceLocationField,
      deviceTypeField,
    );
  });

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
      setFloorSelectDisabled(true);
      setSystemSelectDisabled(true);
    } else {
      setSelectedPropertyId(value);
      setFloorSelectDisabled(false);
      setSystemSelectDisabled(false);
    }

    // on changing property filter option
    // set floor & system filter options to default
    setSelectedFloorId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedSystemId("");
  };

  const onChangeFloor = (value) => {
    if (value) {
      setSelectedFloorId(value);
    }

    // on changing floor filter option
    // set system filter options to default
    setSelectedSystemId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  };

  const onChangeSystem = (value) => {
    if (value) {
      setSelectedSystemId(value);
    }
  };

  const onDateChange = (_, dateString) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const onChangeDateFormat = (value) => {
    if (value) {
      setSelectedDateFormat(value);
    } else {
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);
    }
  };

  const onChangeAggregationLevel = (value) => {
    if (value) {
      setSelectedAggregationLevel(value);
    } else {
      setSelectedAggregationLevel("Device");
    }
  };

  const onChangeBucketType = (value) => {
    if (value) {
      const previousBucketType = selectedBucketType;
      const goingLowerBucketType =
        (previousBucketType === "year" &&
          (value === "month" || value === "day")) ||
        (previousBucketType === "month" && value === "day"); // (year => month => day)
      setDatePickerOption(value);
      setSelectedBucketType(value);

      if (goingLowerBucketType) {
        // we want to update the date (timeframe selection selector) state
        // the logic is as follows:
        // 1. if current bucket type selection is year:
        //    a. if selectedDate is null - we set today's year
        //    b. if selectedDate has value - we set only the value's year
        // 2. if current bucket type selection is month:
        //    a. if selectedDate is null - we set today's year + today's month
        //    b. if selectedDate has value - we set the value's year + the value's month
        // 3. if current bucket type selection is day:
        //    a. if selectedDate is null - we set today's date
        //    b. if selectedDate has value - we set the value's year + the value's month + the value's day
        const newDate = `${new Date(
          selectedDate === null ? dayjs() : selectedDate,
        ).getFullYear()}-${dayjs().month() + 1}-${dayjs().date()}`; // ${dayjs().month() + 1} is not ideal, but javascript-based dayjs() library month values are zero indexed

        setSelectedDate(dayjs(newDate));

        // after updating the selectedDate' state
        // we want to update the actual date picker field in the UI
        generateReportForm.setFieldsValue({
          date: goingLowerBucketType ? dayjs(newDate) : dayjs(selectedDate),
        });
      }
      // the above logic is not necessary for switching to a higher level (day => week => month => year)

      // set date format options according to selected timeframe
      if (value === "day") {
        setDateFormatOptions(dateFormatsForBucketTypeDay);
        setSelectedDateFormat(
          DateConstants.formats
            .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        );

        // after updating the selectedDateFormat's state
        // we want to update the actual selector field in the UI
        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats
              .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        });
      } else if (value === "month") {
        setDateFormatOptions(dateFormatsForBucketTypeMonth);
        setSelectedDateFormat(
          DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        );

        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        });
      } else {
        setDateFormatOptions(dateFormatsForBucketTypeYear);
        setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

        generateReportForm.setFieldsValue({
          dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
        });
      }
    } else {
      setSelectedBucketType("year");
      setDatePickerOption("year");

      setDateFormatOptions(dateFormatsForBucketTypeYear);
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

      generateReportForm.setFieldsValue({
        dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
      });
    }
  };

  const onChangeIncludePreviousYear = (value) => {
    setSelectedIncludePreviousYear(value);
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Row className="reports-filters">
        <Col
          xs={10}
          sm={4}
          xl={3}
          className={`${useBasicFiltering && "mb-3 xl:mb-0"} xs:mr-1 sm:mr-0`}
        >
          <Select
            size="small"
            showSearch
            placeholder="All Properties"
            optionFilterProp="property"
            onChange={onChangeProperty}
            filterOption={filterSelect}
            className="wcr-report-filter-selectors mb-1 xl:mb-2"
            defaultValue=""
            options={propertyOptions}
          />
          <div
            role="presentation"
            onClick={() => setShowHeader(!showHeader)}
            className="wcr-report-filters-buttons"
          >
            <div className="text-ellipsis overflow-hidden whitespace-nowrap">
              {showHeader ? "Hide Header" : "Header"}
            </div>
            <FormOutlined />
          </div>
        </Col>

        {useBasicFiltering === false && (
          <>
            <Col xs={0.1} className="xs:hidden sm:flex items-center mx-1">
              <Divider
                className="py-7"
                style={{ borderColor: "#fff" }}
                type="vertical"
              />
            </Col>
            <Col xs={10} sm={4} xl={3}>
              <div
                role="presentation"
                onClick={() => setIsReportDataModalVisible(true)}
                className="wcr-report-filters-buttons mb-1"
              >
                <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                  Report Data
                </div>
                <FormOutlined />
              </div>
              <div
                role="presentation"
                onClick={() => setGraphics(!graphics)}
                className="wcr-report-filters-buttons"
              >
                <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                  {graphics ? "Table & Graphics" : "Table Only"}
                </div>
                <LineChartOutlined />
              </div>
            </Col>
          </>
        )}

        <Col xs={0.1} className="xs:hidden sm:flex items-center mx-1">
          <Divider
            className="py-7"
            style={{ borderColor: "#fff" }}
            type="vertical"
          />
        </Col>
        <Col
          xs={20}
          sm={6}
          xl={5}
          className={`${
            useBasicFiltering
              ? "sm:mt-5 sm:mb-0 mt-4 mb-1"
              : "sm:mt-5 sm:mb-0 -mb-6"
          }`}
        >
          {useBasicFiltering ? (
            <div
              className={`wcr-report-filters-timeframe-button-fixed ${
                disableFilters && `${disabledOpacity} cursor-not-allowed`
              }`}
            >
              <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                {dayjs().format("llll")}
              </div>
              <CalendarOutlined />
            </div>
          ) : (
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Date field is required." }]}
            >
              <DatePicker
                className="wcr-report-filters-timeframe-button"
                inputReadOnly
                placeholder="Select specific timeframe"
                value={selectedDate}
                onChange={(_, dateString) =>
                  onDateChange(_, dateString, "ReportDate")
                }
                disabledDate={(current) => {
                  if (current.isAfter(dayjs())) {
                    return true;
                  }

                  return false;
                }}
                picker={datePickerOption}
              />
            </Form.Item>
          )}
        </Col>
        <Col xs={0.1} className="xs:hidden sm:flex items-center mx-1">
          <Divider
            className="py-7"
            style={{ borderColor: "#fff" }}
            type="vertical"
          />
        </Col>
        <Col
          xs={20}
          sm={4}
          xl={10}
          className={`xl:mt-0 sm:mt-1 flex xl:flex-row sm:flex-col items-center gap-1 xl:gap-2 ${
            disableFilters && disabledOpacity
          }`}
        >
          <Select
            size="small"
            showSearch
            placeholder="All Floors"
            optionFilterProp="propertyFloor"
            onChange={onChangeFloor}
            filterOption={filterSelect}
            className="wcr-report-filter-selectors"
            disabled={floorSelectDisabled}
            value={selectedFloorId}
            options={floorOptions}
          />
          <Select
            size="small"
            showSearch
            dropdownStyle={{ minWidth: "130px" }}
            placeholder="All Systems"
            optionFilterProp="propertySystem"
            onChange={onChangeSystem}
            filterOption={filterSelect}
            className="wcr-report-filter-selectors"
            disabled={systemSelectDisabled}
            value={selectedSystemId}
            options={systemOptions}
          />
        </Col>
      </Row>
      {useBasicFiltering === false && (
        <FiltersModal
          open={isReportDataModalVisible}
          onClose={() => setIsReportDataModalVisible(false)}
          selectedAggregationLevel={selectedAggregationLevel}
          propertyNameField={propertyNameField}
          setPropertyNameField={setPropertyNameField}
          propertyAddressField={propertyAddressField}
          setPropertyAddressField={setPropertyAddressField}
          floorNameField={floorNameField}
          setFloorNameField={setFloorNameField}
          systemNameField={systemNameField}
          setSystemNameField={setSystemNameField}
          deviceNameField={deviceNameField}
          setDeviceNameField={setDeviceNameField}
          deviceSerialNumberField={deviceSerialNumberField}
          setDeviceSerialNumberField={setDeviceSerialNumberField}
          deviceLocationField={deviceLocationField}
          setDeviceLocationField={setDeviceLocationField}
          deviceTypeField={deviceTypeField}
          setDeviceTypeField={setDeviceTypeField}
          dateFormatOptions={dateFormatOptions}
          onChangeDateFormat={onChangeDateFormat}
          onChangeAggregationLevel={onChangeAggregationLevel}
          onChangeBucketType={onChangeBucketType}
          onChangeIncludePreviousYear={onChangeIncludePreviousYear}
        />
      )}
    </>
  );
}

Filters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
  generateReportForm: {},
  useBasicFiltering: false,
  showHeader: false,
  setShowHeader: () => {},
  graphics: true,
  setGraphics: () => {},
};
Filters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
  generateReportForm: PropTypes.object,
  useBasicFiltering: PropTypes.bool,
  showHeader: PropTypes.bool,
  setShowHeader: PropTypes.func,
  graphics: PropTypes.bool,
  setGraphics: PropTypes.func,
};

export default Filters;
