import PropTypes from "prop-types";

function ReportsManagementIcon({ className = "", strokeColor = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="834"
      height="850"
      viewBox="118 150 160 168"
      fill="none"
      className={className}
    >
      <path
        stroke={strokeColor}
        fill="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.786 273.793h-78.995v-82.994h61.716v16.446h17.279v66.548zm-70.642-8.362h62.288v-49.824h-17.278v-16.446h-45.01v66.27z"
      />
      <path
        stroke={strokeColor}
        fill="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.969 235.987c-2.27 0-4.111-1.791-4.111-4.001 0-2.209 1.841-4.001 4.111-4.001 2.271 0 4.111 1.792 4.111 4.001 0 2.21-1.84 4.001-4.111 4.001zm-12.515 0h-21.102c-2.271 0-4.111-1.792-4.111-4.001 0-2.209 1.84-4.001 4.111-4.001h21.102c2.27 0 4.111 1.792 4.111 4.001 0 2.209-1.841 4.001-4.111 4.001zm-21.102 7.054h33.617c2.271 0 4.111 1.791 4.111 4.001 0 2.21-1.84 4.002-4.111 4.002h-33.617c-2.271 0-4.111-1.792-4.111-4.002s1.84-4.001 4.111-4.001z"
      />
    </svg>
  );
}

ReportsManagementIcon.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
};

ReportsManagementIcon.defaultProps = {
  className: "",
  strokeColor: "",
};

export default ReportsManagementIcon;
