import ConsumptionManagementReportIcon from "../../../../assets/icons/ConsumptionManagementReportIcon";
import CustomerReportIcon from "../../../../assets/icons/CustomerReport";
import useWindowSize from "../../../../hooks/useWindowSize";
import MenuTile from "../../../Shared/MenuTile";

function ReportsMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 mt-1 text-center sm:text-left !text-triple-blue">
          Consumption Reports
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
          Consumption Reports
        </h1>
      )}

      <div className="flex md:flex-row mt-2 flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Management Report"
          icon={<ConsumptionManagementReportIcon className="w-56 align-sub" />}
          navigateTo="/preferences/reports/consumption/water"
        />
        <MenuTile
          title="Customer Report"
          icon={<CustomerReportIcon className="w-56 align-sub" />}
          navigateTo="/preferences/reports/consumption/customer"
        />
      </div>
    </>
  );
}

export default ReportsMenuPage;
