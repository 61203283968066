import { useEffect, useState } from "react";

import { Select } from "antd";
import PropTypes from "prop-types";

import useWaterConsuptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import { isNullOrEmpty } from "../../../helpers/jsLinq";
import "./StatisticsFilters.scss";

function StatisticsFilters({ handleOnChangeFilterCallback }) {
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  const [deviceSelectDisabled, setDeviceSelectDisabled] = useState(true);

  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  const propertyOptions = [
    {
      value: "",
      label: "All Properties",
    },
  ];
  const [floorOptions, setFloorOptions] = useState([
    { value: "", label: "All Floors" },
  ]);
  const [systemOptions, setSystemOptions] = useState([
    { value: "", label: "All Systems" },
  ]);
  const [deviceOptions, setDeviceOptions] = useState([
    { value: "", label: "All Devices" },
  ]);

  // Hooks
  const { data: propertiesObject } = useWaterConsuptionFilters();

  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Effect - Populate Floors dropdown based on selected property
  useEffect(() => {
    if (selectedPropertyId && propertiesObject && propertiesObject.Properties) {
      const tempFloorOptions = [{ value: "", label: "All Floors" }];
      const property = propertiesObject.Properties.find(
        (x) => x.Id === selectedPropertyId,
      );

      if (property) {
        property.Floors?.forEach((f) => {
          tempFloorOptions.push({ value: f.Id, label: f.Name });
        });
      }

      setFloorOptions(tempFloorOptions);
    }
  }, [selectedPropertyId, selectedFloorId, propertiesObject]);

  // Effect - Populate Systems dropdown based on selected floor
  useEffect(() => {
    if (selectedFloorId) {
      const tempSystemOptions = [{ value: "", label: "All Systems" }];
      const property = propertiesObject?.Properties.find(
        (x) => x.Id === selectedPropertyId,
      );

      if (property) {
        const systems = property?.Floors.find(
          (x) => x.Id === selectedFloorId,
        )?.Systems.flatMap((s) => s);
        systems.forEach((s) => {
          tempSystemOptions.push({ value: s.Id, label: s.Name });
        });
      }
      setSystemOptions(tempSystemOptions);
    }
  }, [propertiesObject?.Properties, selectedFloorId, selectedPropertyId]);

  // Effect - Populate Devices dropdown based on selected system
  useEffect(() => {
    if (selectedSystemId) {
      const tempDeviceOptions = [{ value: "", label: "All Devices" }];
      const property = propertiesObject?.Properties.find(
        (x) => x.Id === selectedPropertyId,
      );
      if (property) {
        const devices = property.Floors.flatMap((x) => x.Systems).find(
          (x) => x.Id === selectedSystemId,
        )?.Devices;
        devices.forEach((d) => {
          tempDeviceOptions.push({
            value: d.Id,
            label: !isNullOrEmpty(d.Name)
              ? `${d.Name} - ${d.SerialNumber}`
              : `${d.Type} - ${d.SerialNumber}`,
          });
        });
        setDeviceOptions(tempDeviceOptions);
      }
    }
  }, [propertiesObject?.Properties, selectedSystemId, selectedPropertyId]);

  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
      selectedDeviceId,
    );
  });

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
      setFloorSelectDisabled(true);
    } else {
      setSelectedPropertyId(value);
      setFloorSelectDisabled(false);
    }

    // on changing property filter option
    // set floor & system filter options to default
    setSelectedFloorId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedSystemId("");
    setSystemSelectDisabled(true);
    setSelectedDeviceId("");
    setDeviceSelectDisabled(true);
  };

  const onChangeFloor = (value) => {
    if (value) {
      setSelectedFloorId(value);
      setSystemSelectDisabled(false);
      setSelectedDeviceId("");
      setSelectedSystemId("");
    } else {
      // on changing floor filter option
      // set system filter options to default
      setSelectedFloorId("");
      setSystemSelectDisabled(true);
      setSelectedSystemId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
      setSelectedDeviceId("");
      setDeviceSelectDisabled(true);
    }
  };

  const onChangeSystem = (value) => {
    if (value) {
      setSelectedSystemId(value);
      setDeviceSelectDisabled(false);
    } else {
      setSelectedSystemId("");
      setDeviceSelectDisabled(true);
    }
    setSelectedDeviceId("");
  };

  const onChangeDevice = (value) => {
    if (value) {
      setSelectedDeviceId(value);
    } else {
      setSelectedDeviceId("");
    }
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="statistics-filters md:inline-flex w-full mb-1">
      <Select
        showSearch
        placeholder="All Properties"
        optionFilterProp="property"
        onChange={onChangeProperty}
        filterOption={filterSelect}
        className="property-selector w-full m-1"
        defaultValue=""
        options={propertyOptions}
      />
      <Select
        showSearch
        placeholder="All Floors"
        optionFilterProp="propertyFloor"
        onChange={onChangeFloor}
        filterOption={filterSelect}
        className="floor-selector w-full m-1"
        disabled={floorSelectDisabled}
        value={selectedFloorId}
        options={floorOptions}
      />
      <Select
        showSearch
        placeholder="All Systems"
        optionFilterProp="propertySystem"
        onChange={onChangeSystem}
        filterOption={filterSelect}
        className="system-selector w-full m-1"
        disabled={systemSelectDisabled}
        value={selectedSystemId}
        options={systemOptions}
      />
      <Select
        showSearch
        placeholder="All Devices"
        optionFilterProp="propertyDevice"
        onChange={onChangeDevice}
        filterOption={filterSelect}
        className="system-selector w-full m-1"
        disabled={deviceSelectDisabled}
        value={selectedDeviceId}
        options={deviceOptions}
      />
    </div>
  );
}

StatisticsFilters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
};
StatisticsFilters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
};

export default StatisticsFilters;
