import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import PropTypes from "prop-types";

import { HubIcon, SystemIcon } from "../../../assets/icons/systems";
import Loader from "../../Loader/Loader";

const propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  overviewStatus: PropTypes.object,
  overviewStatusesIsLoading: PropTypes.bool,
};

const defaultProps = {
  className: "",
  overviewStatus: {},
  overviewStatusesIsLoading: false,
};

function PropertyDetails({
  className,
  property,
  overviewStatus,
  overviewStatusesIsLoading,
}) {
  const propertyAlertStatuses = overviewStatus?.SystemStatuses?.filter(
    (s) => s.Severity === "Alert",
  );
  const propertyAlertStatusesCount = propertyAlertStatuses?.length;
  return (
    <div className={`property-details ${className}`}>
      <h3 className="property-details-title">{property?.Name}</h3>
      <div className="property-details-address text-15 text-secondary-white">
        {property?.Address}
      </div>

      <div className="property-details-stats">
        {property?.FloorsCount >= 0 && (
          <>
            <div className="property-details-floors font-light inline-flex items-center">
              <span className="text-lg mx-1">{property.FloorsCount}</span>
              <span className="hidden xxl:inline-flex">Floors</span>
            </div>
            <Divider type="vertical" className="xl:mx-2" />
          </>
        )}

        <Tooltip title="System Count" color="#5a5a5a">
          <div className="property-details-systems">
            <div className="inline-flex font-light">
              <SystemIcon />
              <span className="text-lg mx-1">{property?.SystemsCount}</span>
            </div>
          </div>
        </Tooltip>

        <Divider type="vertical" className="xl:mx-2" />

        <Tooltip title="Device Count" color="#5a5a5a">
          <div className="property-details-devices">
            <div className="inline-flex font-light">
              <HubIcon className="align-sub" />
              <span className="text-lg mx-1">{property?.DevicesCount}</span>
            </div>
          </div>
        </Tooltip>

        <Divider type="vertical" className="xl:mx-2" />

        <div className="property-details-alerts">
          {overviewStatusesIsLoading && <Loader className="justify-center" />}
          {propertyAlertStatuses && propertyAlertStatusesCount >= 0 && (
            <>
              <ExclamationCircleFilled className="text-triple-red text-md xl:text-xl" />
              <div className="font-light inline-flex">
                <span className="text-lg mx-1">
                  {propertyAlertStatusesCount}
                </span>
                <span className="hidden xxl:inline-flex items-center">
                  Alerts
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

PropertyDetails.propTypes = propTypes;
PropertyDetails.defaultProps = defaultProps;

export default PropertyDetails;
