import { useState, useEffect, useCallback } from "react";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";

import useStatisticsAnnualWaterConsumption from "../../../api/hooks/useStatisticsAnnualWaterConsumption";
import useStatisticsBiennialWaterConsumption from "../../../api/hooks/useStatisticsBiennialWaterConsumption";
import DateConstants from "../../../constants/DateConstants";
import useProfileSettings from "../../../hooks/useProfileSettings";
import Loader from "../../Loader/Loader";
import StatisticsCalendar from "../Calendar/StatisticsCalendar";
import StatisticsConsumptionCards from "../ConsumptionCards/StatisticsConsumptionCards";
import StatisticsFilters from "../Filters/StatisticsFilters";
import StatisticsPropertyOverview from "../PropertyOverview/StatisticsPropertyOverview";
import { GetWaterConsumption } from "./services/WaterConsumptionService";
import StatisticsChart from "./StatisticsCharts";

import "./Charts.scss";

function Charts() {
  const dateTimeNow = {
    MM: DateConstants.months[dayjs().$M],
    m: dayjs().$M,
    year: dayjs().$y,
    d: dayjs().$D,
  };

  // States
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [date, setDate] = useState(dateTimeNow);
  const [loading, setLoading] = useState(false);
  const [
    showPreviousWaterConsumptionData,
    setShowPreviousWaterConsumptionData,
  ] = useState(false);
  const [waterConsumptionData, setWaterConsumptionData] = useState();

  // Callbacks
  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    deviceId,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setDeviceId(deviceId);
  };

  const handleTogglePreviousWaterConsumptionData = useCallback(
    () => setShowPreviousWaterConsumptionData((previousShow) => !previousShow),
    [],
  );

  // Fetching water consumption data
  const annualWaterConsumption = useStatisticsAnnualWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId,
      floorId,
      systemId,
      deviceId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const biennialWaterConsumption = useStatisticsBiennialWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId,
      floorId,
      systemId,
      deviceId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const { userSettings } = useProfileSettings();

  useEffect(() => {
    if (date) {
      setLoading(true);
      const fetchData = showPreviousWaterConsumptionData
        ? biennialWaterConsumption.refetch
        : annualWaterConsumption.refetch;
      const yearParam = date.year - 1;
      const statisticsFilters = {
        propertyId,
        floorId,
        systemId,
        deviceId,
      };
      GetWaterConsumption(
        fetchData,
        yearParam,
        statisticsFilters,
        setLoading,
        setWaterConsumptionData,
        userSettings,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    propertyId,
    floorId,
    systemId,
    deviceId,
    date.year,
    annualWaterConsumption.refetch,
    biennialWaterConsumption.refetch,
    userSettings,
    showPreviousWaterConsumptionData,
  ]);

  return (
    <div className="statistics mr-4 md:mr-0">
      <Row>
        <StatisticsPropertyOverview propertyId={propertyId} />
      </Row>
      <Row>
        <StatisticsFilters
          handleOnChangeFilterCallback={handleOnChangeFilterCallback}
        />
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6} />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={15}
          xl={17}
          xxl={18}
          className="content-end"
        >
          <StatisticsConsumptionCards
            waterConsumptionTotal={
              waterConsumptionData?.length > 0 && waterConsumptionData[0]
                ? waterConsumptionData[0].TotalWaterConsumption
                : 0
            }
            showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
            loading={loading}
            onToggle={handleTogglePreviousWaterConsumptionData}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsCalendar
            setDate={setDate}
            date={date}
            property={propertyId}
            floor={floorId}
            systemId={systemId}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={18}>
          {(biennialWaterConsumption.status === "success" ||
            annualWaterConsumption.status === "success") && (
            <StatisticsChart
              statisticsFilters={{
                propertyId,
                floorId,
                systemId,
              }}
              waterConsumptionData={
                waterConsumptionData && waterConsumptionData[0]
                  ? waterConsumptionData[0]
                  : {}
              }
              previousWaterConsumptionData={
                waterConsumptionData && waterConsumptionData[1]
                  ? waterConsumptionData[1]
                  : {}
              }
              showPreviousWaterConsumptionData={
                showPreviousWaterConsumptionData
              }
              setDate={setDate}
              date={date}
            />
          )}
          {biennialWaterConsumption.status === "loading" &&
            annualWaterConsumption.status === "loading" && (
              <div className="flex justify-center mt-10">
                <Loader />
              </div>
            )}
        </Col>
      </Row>
      <Outlet />
    </div>
  );
}

export default Charts;
