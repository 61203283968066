import PropTypes from "prop-types";

function CustomerReportIcon({ className = "", strokeColor = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="834"
      height="850"
      viewBox="124 150 160 168"
      fill="none"
      className={className}
    >
      <path
        stroke={strokeColor}
        fill="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.786 272.79h-78.995v-82.993h61.717v16.446h17.278v66.547zm-70.641-8.362h62.288v-49.824h-17.279v-16.446h-45.009v66.27z"
      />
      <path
        stroke={strokeColor}
        fill="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.07 229.987c-2.27 0-4.111-1.791-4.111-4.001 0-2.209 1.841-4.001 4.111-4.001 2.271 0 4.111 1.792 4.111 4.001 0 2.21-1.84 4.001-4.111 4.001zm-12.515 0h-27.103c-2.271 0-4.11-1.792-4.11-4.001 0-2.209 1.839-4.001 4.11-4.001h27.103c2.27 0 4.111 1.792 4.111 4.001 0 2.209-1.841 4.001-4.111 4.001zM185.453 214.987h20.102c2.27 0 4.111-1.792 4.111-4.001 0-2.209-1.841-4.001-4.111-4.001h-20.102c-2.272 0-4.111 1.792-4.111 4.001 0 2.209 1.839 4.001 4.111 4.001z"
      />
      <path
        stroke="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.378 265.567c4.393-6.042 11.518-9.97 19.56-9.97 8.452 0 15.89 4.338 20.209 10.909M203.542 238.542a7.13 7.13 0 0 1 7.131 7.131 7.13 7.13 0 1 1-7.131-7.131z"
      />
    </svg>
  );
}

CustomerReportIcon.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
};

CustomerReportIcon.defaultProps = {
  className: "",
  strokeColor: "",
};

export default CustomerReportIcon;
