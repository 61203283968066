import { useRef, useCallback, useState, useEffect } from "react";

import {
  DownloadOutlined,
  PrinterOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import {
  Select,
  Col,
  Row,
  Form,
  Tooltip,
  notification,
  Input,
  Avatar,
} from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";

import usePropertyPreferences from "../../../api/hooks/usePropertyPreferences";
import useServiceProvider from "../../../api/hooks/useServiceProvider";
import logo from "../../../assets/img/property-overview-default-pic.png";
import csvParser from "../../../helpers/csvParser";
import { isNullOrEmpty } from "../../../helpers/jsLinq";
import ImageUploader from "../../ImageUploader/ImageUploader";
import Chart from "./Chart";
import Filters from "./Filters";
import Table from "./Table";

import "./Print.css";

function ReportsLayout({
  iconTitle,
  icon,
  fetchData,
  useGraph,
  handleFieldsChange,
  initialFormValues,
  handleOnChangeFilterCallback,
  generateReportForm,
  reportDate,
  propertyId,
  floorId,
  systemId,
  includePreviousYear,
  setReportDate,
  useBasicFiltering,
  showData,
  enableStatusFilter,
}) {
  const [pageSizeAndOrientation, setPageSizeAndOrientation] =
    useState("A4 landscape");
  const [data, setData] = useState(null);
  const [csv, setCSV] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showHeader, setShowHeader] = useState(false);
  const [graphics, setGraphics] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileListArr, setFileListArr] = useState([]);

  const printOptions = [
    {
      value: "A4 portrait",
      label: "Portrait",
    },
    {
      value: "A4 landscape",
      label: "Landscape",
    },
  ];

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangePrintOption = (value) => {
    setPageSizeAndOrientation(value);
  };

  const setPageSize = (pageSizeAndOrientation) => {
    const style = document.createElement("style");
    style.innerHTML = `@page {size: ${pageSizeAndOrientation}}`;
    style.id = "page-orientation";
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize(pageSizeAndOrientation);
    return () => {
      const child = document.getElementById("page-orientation");
      child.parentNode.removeChild(child);
    };
  }, [pageSizeAndOrientation]);

  const { data: property, refetch: getPropertyPreferences } =
    usePropertyPreferences(propertyId, {
      enabled: false,
      cacheTime: 0,
    });

  const { data: serviceProvider, refetch: getServiceProvider } =
    useServiceProvider(
      property?.ServiceProviderId,
      {
        enabled: false,
        cacheTime: 0,
      },
      `${property?.ServiceProviderId}-getServiceProvider`,
    );

  useEffect(() => {
    if (propertyId !== "all" && !isNullOrEmpty(propertyId)) {
      setLoading(true);
      getPropertyPreferences(propertyId).then(() => {
        if (property && property?.ServiceProviderId) {
          getServiceProvider(property?.ServiceProviderId).then(() => {
            setLoading(false);
          });
        }
      });

      console.log(property);
    }
  }, [
    propertyId,
    getPropertyPreferences,
    property,
    property?.ServiceProviderId,
    getServiceProvider,
  ]);

  const onImageChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const imageRequest = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess("ok");
      }
    }, 1000);
  };

  const componentToPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const handleReportGenerate = useCallback(() => {
    setLoading(true);
    fetchData().then((response) => {
      if (response && response.isSuccess) {
        setCSV(response.data.Value);
        setFileName(response.data.FileName);

        // Parsing the raw CSV string to table format
        const parsedData = csvParser(response.data.Value);

        // Extract headers and rows
        const headers = parsedData[0];
        const tableData = parsedData.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header.trim()] = row[index] ? row[index].trim() : "";
          });
          return obj;
        });

        setData(tableData);
        setLoading(false);
      } else {
        notification.info({
          message: (
            <span className="uppercase tracking-widest">Loading data...</span>
          ),
          description: (
            <span className="uppercase tracking-widest">
              {response && response.error && response.error.response
                ? response.error.response.data[0]
                : ""}
            </span>
          ),
          duration: 3,
          placement: "topRight",
        });

        // Clear report data if no records are found from back-end
        setData(null);
        setCSV(null);
        setFileName(null);
        setLoading(false);
      }
    });
  }, [fetchData]);

  const handleExport = useCallback(() => {
    if (data && data.length > 0) {
      const timestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Please generate report.
          </span>
        ),
        description: (
          <span className="uppercase tracking-widest">
            No report data to export. Please generate report first.
          </span>
        ),
        duration: 10,
        placement: "topRight",
        icon: <FrownOutlined className="text-triple-red" />,
      });
    }
  }, [data, csv, fileName]);

  return (
    <Row className="w-full">
      <Col className="w-full xxl:w-[90%] flex xs:flex-col-reverse sm:flex-row">
        <Col ref={componentToPrintRef} className="m-auto p-2 w-full">
          <Row className="w-full xxxl:gap-0 gap-2">
            <Col className="flex w-full gap-2">
              <Col className="flex flex-col xs:w-32 xs:h-32 sm:w-24 sm:h-24 xl:w-20 xl:h-20 text-center items-center rounded-md bg-triple-white p-2 xs:gap-4 sm:gap-2 xl:gap-1">
                <Col className="text-triple-background uppercase xs:text-[10px] sm:text-[8px]">
                  {iconTitle}
                </Col>
                <Col className="xs:w-20 xs:h-20 sm:w-14 sm:h-14 xl:w-12 xl:h-12 border-[5px] border-triple-blue border-solid rounded-full flex items-center justify-center">
                  {icon}
                </Col>
              </Col>
              <Form
                form={generateReportForm}
                name="generate-report-form"
                onFinish={handleReportGenerate}
                onFieldsChange={handleFieldsChange}
                initialValues={initialFormValues.current}
                className="w-full"
              >
                <Filters
                  handleOnChangeFilterCallback={handleOnChangeFilterCallback}
                  generateReportForm={generateReportForm}
                  useBasicFiltering={useBasicFiltering}
                  showHeader={showHeader}
                  setShowHeader={setShowHeader}
                  graphics={graphics}
                  setGraphics={setGraphics}
                />
              </Form>
            </Col>
          </Row>
          {showData && (
            <Row className="pt-2 gap-2 w-full">
              {showHeader && (
                <Row className="w-full justify-between items-center content-center text-triple-header px-1">
                  <Col span={12} className="bg-triple-white rounded-md -ml-1">
                    <Row className="gap-6 p-3 w-full justify-between">
                      <Row className="gap-2">
                        <Col>
                          <Avatar
                            shape="square"
                            title="Property"
                            src={property?.ImageUrl ?? logo}
                            className="h-24 w-24 flex items-center justify-center cursor-pointer bg-triple-white-new text-triple-background mr-2"
                          />
                        </Col>
                        <Col className="justify-between">
                          <Row className="mb-4 mt-2">
                            Property: {property?.Name}
                          </Row>
                          <Row className="my-2">
                            Address:{" "}
                            {property?.Address ??
                              "Property does not have a designated address"}
                          </Row>
                          <Row>
                            <Row className="mt-2 mr-1">Type:</Row>
                            <Row className="mt-1">
                              <Input
                                variant="borderless"
                                size="small"
                                className="bg-triple-white text-triple-header w-fit"
                              />
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col span={12} className="bg-triple-white rounded-md -mr-1">
                    <Row className="gap-6 p-3 w-full justify-between">
                      <Row className="gap-2">
                        <Col className="h-24">
                          <ImageUploader
                            fileListArr={fileListArr}
                            onRemoveImg={onRemoveImg}
                            onChange={onImageChange}
                            request={imageRequest}
                            autoUpload={false}
                          />
                        </Col>
                        <Col className="justify-between">
                          <Row className="mt-2 mb-1">
                            Contact: {serviceProvider?.Name}
                          </Row>
                          <Row className="my-1">
                            Address: {serviceProvider?.SupportSite}
                          </Row>
                          <Row className="my-1">
                            Email: {serviceProvider?.SupportEmail}
                          </Row>
                          <Row className="mb-2 mt-1">
                            Phone: {serviceProvider?.SupportPhone}
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              )}
              <Col span={24}>
                <Table
                  data={data}
                  loading={loading}
                  enableStatusFilter={enableStatusFilter}
                />
              </Col>
              {useGraph && graphics && (
                <Col span={24}>
                  <Chart
                    date={reportDate}
                    propertyId={propertyId ? propertyId.toString() : "all"}
                    floorId={floorId}
                    systemId={systemId}
                    showPreviousWaterConsumptionData={includePreviousYear}
                    setDate={setReportDate}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col className="sm:mt-4 xs:m-auto items-center flex sm:flex-col xs:flex-row gap-3">
          <Tooltip title="Print Report" color="#06a5d3" key="print">
            <PrinterOutlined
              className="text-18 cursor-pointer text-triple-white hover:text-triple-blue"
              onClick={() => handlePrint()}
            />
          </Tooltip>
          <Tooltip title="Download CSV File" color="#06a5d3" key="csv">
            <DownloadOutlined
              className="text-18 cursor-pointer text-triple-white hover:text-triple-blue"
              onClick={handleExport}
            />
          </Tooltip>
        </Col>
        <Col className="sm:mt-3 ml-2">
          <Select
            size="small"
            placeholder="Select Print Option"
            optionFilterProp="printOption"
            onChange={onChangePrintOption}
            filterOption={filterSelect}
            defaultValue={pageSizeAndOrientation}
            options={printOptions}
          />
        </Col>
      </Col>
    </Row>
  );
}

ReportsLayout.defaultProps = {
  iconTitle: null,
  icon: null,
  fetchData: () => {},
  useGraph: false,
  handleFieldsChange: () => {},
  initialFormValues: {},
  handleOnChangeFilterCallback: () => {},
  generateReportForm: {},
  reportDate: null,
  propertyId: null,
  floorId: null,
  systemId: null,
  includePreviousYear: null,
  setReportDate: () => {},
  useBasicFiltering: false,
  showData: false,
  enableStatusFilter: false,
};
ReportsLayout.propTypes = {
  iconTitle: PropTypes.string,
  icon: PropTypes.any,
  fetchData: PropTypes.func,
  useGraph: PropTypes.bool,
  handleFieldsChange: PropTypes.func,
  initialFormValues: PropTypes.object,
  handleOnChangeFilterCallback: PropTypes.func,
  generateReportForm: PropTypes.object,
  reportDate: PropTypes.any,
  propertyId: PropTypes.any,
  floorId: PropTypes.any,
  systemId: PropTypes.any,
  includePreviousYear: PropTypes.bool,
  setReportDate: PropTypes.func,
  useBasicFiltering: PropTypes.bool,
  showData: PropTypes.bool,
  enableStatusFilter: PropTypes.bool,
};

export default ReportsLayout;
