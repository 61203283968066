import { useState, useRef, useEffect } from "react";

import { Form } from "antd";

import useStatusReport from "../../api/hooks/useStatusReport";
import ExclamationMarkIcon from "../../assets/icons/ExclamationMarkIcon";
import useWindowSize from "../../hooks/useWindowSize";
import ReportsLayout from "./Layout/ReportsLayout";

function StatusReport() {
  const [showData, setShowData] = useState(false);
  const [generateReportForm] = Form.useForm();
  const initialFormValues = useRef({});
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [deviceTypeField, setDeviceTypeField] = useState(null);
  const [deviceStatusField, setDeviceStatusField] = useState(null);
  const [deviceLocationField, setDeviceLocationField] = useState(null);
  const [floorNameField, setFloorNameField] = useState(null);
  const [ownerUnitField, setOwnerUnitField] = useState(null);
  const [systemNameField, setSystemNameField] = useState(null);
  const [deviceSerialNumberField, setDeviceSerialNumberField] = useState(null);
  const [deviceBatteryField, setDeviceBatteryField] = useState(null);
  const [deviceSignalField, setDeviceSignalField] = useState(null);

  const window = useWindowSize();

  useEffect(() => {
    if (propertyId !== null && propertyId > 0) {
      setShowData(true);
      generateReportForm.submit();
    }
  }, [
    generateReportForm,
    propertyId,
    floorId,
    systemId,
    deviceTypeField,
    deviceStatusField,
    deviceLocationField,
    floorNameField,
    ownerUnitField,
    systemNameField,
    deviceSerialNumberField,
    deviceBatteryField,
    deviceSignalField,
  ]);

  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    deviceTypeField,
    deviceStatusField,
    deviceLocationField,
    floorNameField,
    ownerUnitField,
    systemNameField,
    deviceSerialNumberField,
    deviceBatteryField,
    deviceSignalField,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setDeviceTypeField(deviceTypeField);
    setDeviceStatusField(deviceStatusField);
    setDeviceLocationField(deviceLocationField);
    setFloorNameField(floorNameField);
    setOwnerUnitField(ownerUnitField);
    setSystemNameField(systemNameField);
    setDeviceSerialNumberField(deviceSerialNumberField);
    setDeviceBatteryField(deviceBatteryField);
    setDeviceSignalField(deviceSignalField);
  };

  const { refetch: getStatusReport } = useStatusReport(
    {
      filtersWithDeviceId: {
        deviceId: null,
        propertyId: propertyId === "" ? null : propertyId,
        floorId: floorId === "" ? null : floorId,
        systemId: systemId === "" ? null : systemId,
      },
      reportFileType: "CSV",
      fields: {
        DeviceType: deviceTypeField,
        Status: deviceStatusField,
        DeviceLocation: deviceLocationField,
        FloorName: floorNameField,
        OwnerUnit: ownerUnitField,
        SystemName: systemNameField,
        DeviceSerialNumber: deviceSerialNumberField,
        DeviceBattery: deviceBatteryField,
        DeviceSignal: deviceSignalField,
      },
    },
    { enabled: false },
  );

  return (
    <>
      <div className="flex-1 mt-6 md:mt-1 flex lg:items-center flex-col lg:flex-row">
        {window?.width > 768 && <h1 className="page-title">Status Report</h1>}
      </div>
      <ReportsLayout
        iconTitle="Status"
        icon={<ExclamationMarkIcon />}
        fetchData={getStatusReport}
        initialFormValues={initialFormValues}
        handleOnChangeFilterCallback={handleOnChangeFilterCallback}
        generateReportForm={generateReportForm}
        propertyId={propertyId}
        floorId={floorId}
        systemId={systemId}
        useBasicFiltering
        showData={showData}
        enableStatusFilter
      />
    </>
  );
}

export default StatusReport;
