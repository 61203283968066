import { Row, Col, Divider } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import "./Property.scss";

import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../Loader/Loader";
import PropertyWaterConsumptionChart from "../PropertyCharts/PropertyWaterConsumptionChart";
import PropertyWaterSavingsChart from "../PropertyCharts/PropertyWaterSavingsChart";
import PropertyDetails from "./PropertyDetails";
import PropertyImage from "./PropertyImage";

const propTypes = {
  className: PropTypes.string,
  property: PropTypes.object,
  waterConsumptionCollectionData: PropTypes.array,
  overviewStatusesCollectionData: PropTypes.array,
  waterConsumptionIsLoading: PropTypes.bool,
  overviewStatusesIsLoading: PropTypes.bool,
};

const defaultProps = {
  className: "",
  property: {},
  waterConsumptionCollectionData: [],
  overviewStatusesCollectionData: [],
  waterConsumptionIsLoading: false,
  overviewStatusesIsLoading: false,
};

function PropertyCard({
  className,
  property,
  waterConsumptionCollectionData,
  overviewStatusesCollectionData,
  waterConsumptionIsLoading,
  overviewStatusesIsLoading,
}) {
  const navigate = useNavigate();
  const window = useWindowSize();
  const { Id } = property;
  const waterConsumption = waterConsumptionCollectionData?.find(
    (waterConsumption) => waterConsumption.Id === Id,
  );
  const overviewStatus = overviewStatusesCollectionData?.find(
    (overviewStatus) => overviewStatus.Id === Id,
  );
  const propertyAlertStatuses = overviewStatus?.SystemStatuses?.filter(
    (s) => s.Severity === "Alert",
  );
  return (
    <div
      role="none"
      className={`property sm:my-2 my-4 ${className} ${
        propertyAlertStatuses?.length > 0
          ? "border-triple-red"
          : "border-triple-blue"
      } `}
      onClick={() => {
        navigate(`property/${property?.Id}`, {
          state: {
            property: {
              ...property,
              TotalWaterConsumption: waterConsumption?.TotalWaterConsumption,
              WaterSavingsPercentage:
                waterConsumption?.TotalWaterConsumption?.WaterSavingsPercentage,
              AlertsCount: propertyAlertStatuses?.length,
            },
          },
        });
      }}
    >
      <Row className="h-full">
        {/* PROPERTY IMG */}
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <PropertyImage imageUrl={property?.ImageUrl} />
        </Col>

        {/* PROPERTY DETAILS */}
        <Col xs={{ span: 24 }} lg={{ span: 6 }} className="truncate">
          <PropertyDetails
            property={property}
            overviewStatus={overviewStatus}
            overviewStatusesIsLoading={overviewStatusesIsLoading}
          />
        </Col>

        {/* DIVIDER */}
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0 md:ml-5"
          />
        </Col>

        {/* CHARTS */}
        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          <div className="property-charts">
            {waterConsumptionIsLoading && <Loader className="justify-center" />}
            {waterConsumption && (
              <PropertyWaterConsumptionChart
                className="justify-between"
                data={waterConsumption?.TotalWaterConsumption}
              />
            )}
            {window?.width < 768 && window?.width > 576 && (
              <Divider type="horizontal" className="my-4 lg:h-4/5 lg:my-0" />
            )}
            {waterConsumptionIsLoading && <Loader className="justify-center" />}
            {waterConsumption && (
              <PropertyWaterSavingsChart
                className="justify-between"
                percent={
                  waterConsumption?.TotalWaterConsumption
                    ?.WaterSavingsPercentage
                }
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

PropertyCard.propTypes = propTypes;
PropertyCard.defaultProps = defaultProps;

export default PropertyCard;
